import { useSession } from '@faststore/sdk'
import type {
  BooxieMobilePageQueryQuery,
  BooxieMobilePageQueryQueryVariables,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
// import { Helmet } from 'react-helmet'
// import LazyRenderCMS from 'src/components/LazyRenderCMS'
import RenderCMS from 'src/components/RenderCMS'
import { RenderClient } from 'src/components/RenderClient'
import { mark } from 'src/sdk/tests/mark'

type Props = PageProps<
  BooxieMobilePageQueryQuery,
  BooxieMobilePageQueryQueryVariables,
  unknown
> & { slug: string }

function Page(props: Props) {
  const {
    data: { site, cmsBooxieMobilePage },
  } = props

  const cmsData = cmsBooxieMobilePage
  console.log('cmsData: ', cmsData)

  const title =
    cmsData?.seo?.siteMetadataWithSlug?.title ?? site?.siteMetadata?.title ?? ''

  const titleTemplate = site?.siteMetadata?.titleTemplate
  const description =
    cmsData?.seo?.siteMetadataWithSlug?.description ??
    site?.siteMetadata?.description ??
    ''

  const seoSlug = cmsData?.seo?.siteMetadataWithSlug?.slug
  const { locale } = useSession()
  const canonical =
    cmsData?.config?.slugAndFilterConfig?.canonical ??
    `${site?.siteMetadata?.siteUrl}${seoSlug}`

  const noindex = cmsData?.config?.slugAndFilterConfig?.noIndex ?? false
  const nofollow = cmsData?.config?.slugAndFilterConfig?.noFollow ?? false

  // const externalScripts =
  //   cmsData?.config?.slugAndFilterConfig?.externalScripts ?? []
  // console.log('externalScripts: ', externalScripts)

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title ?? ''}
        titleTemplate={titleTemplate ?? ''}
        description={description ?? ''}
        canonical={canonical}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description,
        }}
        nofollow={nofollow}
        noindex={noindex}
      />
      {/* {externalScripts.map((script) => (
        <Helmet>
          <script src={script ?? ''}></script>
        </Helmet>
      ))} */}
      <RenderClient>
        <main className="main-max-width">
          <section className="page__section cms-departament-components">
            <RenderCMS sections={cmsData?.sections} />
            {/* <LazyRenderCMS sections={cmsData?.sections} /> */}
          </section>
        </main>
      </RenderClient>
    </>
  )
}

export const querySSG = graphql`
  query BooxieMobilePageQuery($slug: String) {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    cmsBooxieMobilePage(
      config: { slugAndFilterConfig: { slug: { eq: $slug } } }
    ) {
      sections {
        data
        name
      }
      seo {
        siteMetadataWithSlug {
          slug
          description
          title
          titleTemplate
        }
      }
      config {
        slugAndFilterConfig {
          slug
          canonical
          externalScripts
          noIndex
          noFollow
          noLayout
        }
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)
